import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import SeeAllRecipesLink from '../generic/SeeAllRecipesLink';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import AzPage from '../generic/aToZ/Main';
import ImageHero from '../generic/ImageHero';
import { Header2 } from '../generic/PageHeader';
import ContentSeparator from '../generic/ContentSeparator';
import { promoShape } from '../promo/shapes';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({
  azCounts,
  totalCount,
  activeTab,
  id,
  imageUrl,
  letter,
  page,
  title,
  description,
  relatedRecipes,
  relatedDishes,
  relatedIngredients,
}) => {
  const hasRelatedDishes = relatedDishes && relatedDishes.length > 0;
  const hasRelatedIngredients = relatedIngredients && relatedIngredients.length > 0;
  const baseUrl = `/food/cuisines/${id}`;

  return (
    <>
      <MetaProperties title={`${title} recipes`} description={description} imageUrl={imageUrl} />
      <ImageHero imageUrl={imageUrl} title={title} description={description} />
      {relatedRecipes.length > 0 && (
        <>
          <Header2 title={`${upperFirst(title)} recipes`} anchor="recipes" />
          <AzPage
            azCounts={azCounts}
            baseUrl={baseUrl}
            activeTab={activeTab}
            entity="recipes"
            letter={letter}
            page={page}
            promos={relatedRecipes}
            promoContainerClassName="cuisine-page"
            anchor="recipes"
            totalCount={totalCount}
          />
          {activeTab === 'featured' && (
            <div className="see-all-recipes__wrap gel-wrap">
              <SeeAllRecipesLink
                body={`all ${title} recipes`}
                totalCount={totalCount}
                href={`/food/search?cuisines=${id}`}
              />
            </div>
          )}
        </>
      )}

      {(hasRelatedDishes || hasRelatedIngredients) && (
        <div className="food-body__grey-background">
          {hasRelatedDishes && (
            <>
              <StandardCollection6Promos
                id={`related_dishes_${id}`}
                title="Related dishes"
                promos={relatedDishes}
                maxCollectionSize={relatedDishes.length}
              />
              <ContentSeparator />
            </>
          )}
          {hasRelatedIngredients && (
            <>
              <StandardCollection6Promos
                id={`related_ingredients_${id}`}
                title="Related ingredients"
                promos={relatedIngredients}
                maxCollectionSize={relatedIngredients.length}
              />
              <ContentSeparator />
            </>
          )}
        </div>
      )}
    </>
  );
};

Page.defaultProps = {
  title: undefined,
  activeTab: 'featured',
  azCounts: {},
  letter: '',
  page: 1,
};

Page.propTypes = {
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  totalCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  letter: PropTypes.string,
  page: PropTypes.number,
  title: PropTypes.string,
  activeTab: PropTypes.oneOf(['all', 'featured']),
  description: PropTypes.string.isRequired,
  relatedRecipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  relatedDishes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  relatedIngredients: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
};

export default connect(state => ({
  azCounts: state.cuisineReducer.azCounts,
  totalCount: state.cuisineReducer.totalCount,
  activeTab: state.cuisineReducer.activeTab,
  id: state.cuisineReducer.id,
  imageUrl: state.cuisineReducer.imageUrl,
  letter: state.cuisineReducer.letter,
  page: state.cuisineReducer.page,
  title: state.cuisineReducer.title,
  description: state.cuisineReducer.description,
  relatedRecipes: state.cuisineReducer.relatedRecipes,
  relatedDishes: state.cuisineReducer.relatedDishes,
  relatedIngredients: state.cuisineReducer.relatedIngredients,
}))(Page);
